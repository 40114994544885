import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
    children,
    onClick,
    type = 'button',
    variant = 'primary',
    size = 'md',
    shape = 'default', // default or circular
    disabled = false,
    className = '',
}) => {
    const baseStyles = `font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition ease-in-out duration-200 px-[40px]`;

    const variantStyles = {
        primary: 'btn-primary',
        secondary: 'btn-secondary',
        danger: 'btn-danger',
        outline: 'border border-primary hover:bg-primary', // Outline button styles
    };

    const sizeStyles = {
        sm: 'btn-sm',
        md: 'btn-md',
        lg: 'btn-lg',
    };

    const shapeStyles = shape === 'circular' ? 'btn-circular' : 'rounded-md';

    const disabledStyles = disabled
        ? 'opacity-50 cursor-not-allowed'
        : 'cursor-pointer';

    const combinedStyles = `${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${shapeStyles} ${disabledStyles} ${className}`;

    return (
        <button
            type={type}
            onClick={disabled ? null : onClick}
            disabled={disabled}
            className={combinedStyles}
        >
            {children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'outline']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    shape: PropTypes.oneOf(['default', 'circular']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
};

export default Button;
