import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';

const CopyToClipboard = ({
    textToCopy,
    children,
    tooltipMessage = 'Copied!',
    hoverMessage = 'Click to copy',
}) => {
    const [isCopied, setIsCopied] = useState(false);
    const primaryColor = '#4F46E5';
    const [showToolTip, setShowTooltip] = useState(true);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setIsCopied(true);
            setTimeout(() => {
                setShowTooltip(false);
                const btn = document.getElementById('clip-btn');
                if (btn) {
                    btn.blur();
                }
                setShowTooltip(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 500);
            }, 2000); // Reset tooltip after 2 seconds
        } catch (error) {
            console.error('Failed to copy text: ', error);
        }
    };

    return (
        <div className="copy-to-clipboard">
            <button
                id="clip-btn"
                onClick={handleCopy}
                data-tooltip-id="clipboard-tooltip"
                data-tooltip-content={isCopied ? tooltipMessage : hoverMessage}
            >
                {children}
            </button>
            {showToolTip ? (
                <Tooltip
                    id="clipboard-tooltip"
                    style={{ background: primaryColor }}
                />
            ) : null}
        </div>
    );
};

export default CopyToClipboard;
