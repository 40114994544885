import { Button } from '../../components';

const RoundContent = ({ game, roundState, handleSubmitAnswer }) => {
    if (
        !game ||
        !game.round ||
        roundState.isRecording ||
        roundState.isLoadingRound
    ) {
        return (
            <div className="text-2xl text-gray-400">
                <div className="min-h-[332px] flex flex-col justify-center">
                    {roundState.isRecording || roundState.isLoadingRound
                        ? 'Starting round...'
                        : 'Waiting for round to start'}
                </div>
            </div>
        );
    }

    const {
        question,
        round_number,
        possible_answers,
        playerAnswer,
        correct_answer,
    } = game.round;

    const getAnswerClass = (answer) => {
        const baseClass = 'border rounded-md py-3 cursor-not-allowed';

        // wait until round is unlocked to show answers
        if (game.round?.playerAnswer?.answer === answer) {
            if (roundState.isRoundLocked) {
                return `${baseClass} font-bold`;
            } else {
                if (game.round?.playerAnswer?.is_answer_correct) {
                    return `border-green-400 bg-green-400 ${baseClass}`;
                } else {
                    return `border-red-400 bg-red-400 ${baseClass}`;
                }
            }
        }

        return `${baseClass} opacity-40`;
    };

    return (
        <div>
            <div className="text-2xl border-b mb-2 -ml-4 -mr-4 px-4 pb-2 flex">
                <div className="grow text-left">{question}</div>
                <div className="text-base flex flex-col justify-center">
                    {round_number ? `Round ${round_number}` : ''}
                </div>
            </div>
            <div className="flex flex-col gap-[10px] py-2 min-h-[270px]">
                {possible_answers.map((answer, index) => (
                    <div key={index}>
                        {playerAnswer ? (
                            <div className={getAnswerClass(answer)}>
                                {answer}
                            </div>
                        ) : (
                            <Button
                                variant="outline"
                                onClick={() => handleSubmitAnswer(answer)}
                                className="w-full"
                            >
                                {answer}
                            </Button>
                        )}
                    </div>
                ))}
                <div className="text-left min-h-[28px]">
                    {playerAnswer && !roundState.isRoundLocked && (
                        <span
                            className={
                                playerAnswer.is_answer_correct
                                    ? 'text-green-400'
                                    : 'text-red-400'
                            }
                        >
                            {playerAnswer.is_answer_correct
                                ? 'Correct!'
                                : `Correct answer was '${correct_answer}'`}
                        </span>
                    )}
                    {playerAnswer && roundState.isRoundLocked && (
                        <span className="text-gray-200">Answer submitted</span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RoundContent;
