import { useState, useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router';
import { Input, Button } from '../../components';
import { useGame } from '../../context/GameContext';
import { useNavigate } from 'react-router';

import './JoinGame.scss';

const GAME_CODE_LENGTH = 5;

const JoinGame = () => {
    const navigate = useNavigate();

    const { isLoadingGame, joinGame } = useGame();
    const [searchParams] = useSearchParams();
    const { gameCode: pathGameCode } = useParams();

    const [gameData, setGameData] = useState({
        game_code: '',
        player_name: '',
    });
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Apply uppercase and max-length only for "game_code"
        const transformedValue =
            name === 'game_code'
                ? value.toUpperCase().slice(0, GAME_CODE_LENGTH) // Uppercase and limit to 5 characters
                : value;

        setGameData({
            ...gameData,
            [name]: transformedValue,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const joinedGame = await joinGame(gameData);

        if (joinedGame) {
            navigate('/play-game');
        } else {
            setError('Problem joining game');
        }
    };

    // Check for gameCode from either query params or path params
    // to pre populate game_code field
    useEffect(() => {
        const queryGameCode = searchParams.get('game_code');
        let game_code = '';

        if (queryGameCode) {
            game_code = queryGameCode;
        } else if (pathGameCode) {
            game_code = pathGameCode;
        }

        setGameData({
            ...gameData,
            game_code: game_code.toUpperCase().slice(0, GAME_CODE_LENGTH),
        });
    }, [pathGameCode, searchParams]);

    return (
        <div>
            <div className="flex flex-col gap-[20px] text-center max-w-[500px] m-auto py-[20px] px-[10px] mt-[20px] md:mt-[40px]">
                <h1 className="text-[2.8rem] md:text-[3.5rem]">
                    Ready to play?
                </h1>
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-[20px]">
                        <div>
                            <Input
                                label="Game Code"
                                id="game_code"
                                placeholder="Enter game code"
                                value={gameData.game_code}
                                onChange={handleInputChange}
                                size="md"
                                variant="default"
                                required
                                name="game_code"
                                className="bg-gray-800"
                            />
                        </div>
                        <div>
                            <Input
                                label="Name"
                                id="player_name"
                                placeholder="Enter your name"
                                value={gameData.player_name}
                                onChange={handleInputChange}
                                size="md"
                                variant="default"
                                required
                                name="player_name"
                                className="bg-gray-800"
                            />
                        </div>
                        {error && <div className="text-danger">{error}</div>}
                        <div className="mt-[20px]">
                            <Button type="submit" disabled={isLoadingGame}>
                                Join Game
                            </Button>
                        </div>
                    </div>
                </form>
                <div className="text-gray-300 -mt-[10px]">
                    Need help? Go{' '}
                    <Link
                        to="/how-to-play"
                        className="text-primary hover:text-primary-hover"
                    >
                        here
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default JoinGame;
