const StatusMessage = ({ roundState, recordingTimer, roundLockTimer }) => {
    return (
        <div className="text-2xl mb-[10px] text-gray-400">
            {roundState.isLoadingRound ? (
                <>
                    {roundState.roundStartedBy ? (
                        <div>Round started by {roundState.roundStartedBy}</div>
                    ) : (
                        <div>Analyzing audio...</div>
                    )}
                </>
            ) : roundState.isRecording ? (
                <div>Recording {recordingTimer}</div>
            ) : roundState.isRoundLocked ? (
                <div>Waiting for answers {roundLockTimer}</div>
            ) : (
                <div className="text-white">Ready to start a round?</div>
            )}
        </div>
    );
};

export default StatusMessage;
