import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Toast } from '../../components';
import { useNavigate } from 'react-router';

const VerifyToken = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const [values, setValues] = useState(['', '', '', '', '']);
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isBadCode, setIsBadCode] = useState(false);

    const focusFirst = () => {
        document.getElementById('input-0').focus();
    };

    useEffect(() => {
        const token = searchParams.get('token');

        // if the url has a token passed in and the length is 5, auto submit
        if (token && token.length === 5) {
            const tokenArray = token.split('');
            setValues(tokenArray);

            // Automatically submit the token
            handleSubmit(tokenArray.join(''));

            // blow away the token after attempting to submit
            // (we dont want a refresh or something with a bad key)
            const newUrl = window.location.origin + window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
        } else {
            // Focus the first input box on load
            // document.getElementById('input-0').focus();
            focusFirst();
        }
    }, [searchParams]);

    const handleChange = (e, index) => {
        setIsBadCode(false); // reset bad code on change

        const updatedValues = [...values];
        updatedValues[index] = e.target.value.slice(0, 1).toUpperCase(); // Limit to one character
        setValues(updatedValues);

        // Move focus to the next input box
        if (e.target.value && index < values.length - 1) {
            document.getElementById(`input-${index + 1}`).focus();
        }

        // If all boxes are filled, submit the token
        if (updatedValues.every((char) => char)) {
            handleSubmit(updatedValues.join(''));
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !values[index] && index > 0) {
            // Move focus to the previous box on Backspace if current is empty
            document.getElementById(`input-${index - 1}`).focus();
        }
    };

    const handleSubmit = async (token) => {
        setIsLoading(true);

        try {
            const response = await axios.post(`${apiBaseUrl}/api/verify`, {
                token,
            });

            Toast.success('Token successfully verified!');

            navigate('/create-game');
        } catch (error) {
            console.error('token validation error:', error);

            if (error.status === 429) {
                Toast.error('Too many attempts');
            } else if (error.status === 404) {
                Toast.error('Token not found or already validated');
                setIsBadCode(true);
            } else {
                Toast.error('Problem validating token');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const baseClass =
        'w-14 h-14 md:w-20 md:h-20 text-center text-xl md:text-4xl font-medium border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500 text-primary';

    return (
        <div className="flex flex-col items-center mx-auto px-6 py-[200px] md:py-[250px]">
            <h1 className="text-3xl md:text-4xl mb-4 md:mb-6">
                Enter code from email
            </h1>
            <div className="flex gap-4">
                {values.map((value, index) => (
                    <input
                        key={index}
                        id={`input-${index}`}
                        type="text"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        maxLength="1"
                        placeholder="-"
                        className={
                            isBadCode
                                ? `${baseClass} border-red-500 text-red-500`
                                : baseClass
                        }
                        disabled={isLoading}
                    />
                ))}
            </div>
            {isBadCode && (
                <div className="text-red-500 text-xl mt-2">Invalid code</div>
            )}
        </div>
    );
};

export default VerifyToken;
