import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUsers,
    faGear,
    faTrash,
    faCopy,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, CopyToClipboard } from '../../components';

import { useGame } from '../../context/GameContext';

// game_code copy/share behavior (copy to clipboard, or maybe share link? /join-game?id=12345 to pre populate field for them)
const GameManager = () => {
    const { game, leaveGame, removePlayer, isLoadingGame, isUpdatingGame } =
        useGame();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleLeaveGame = () => {
        const toastMsg = game.is_creator
            ? 'Game successfully ended'
            : 'Thanks for playing!';

        const leftGame = leaveGame(toastMsg);

        if (leftGame) {
            handleCloseModal();
        }
    };

    const handleRemovePlayer = async (player) => {
        const playerRemoved = await removePlayer({
            id: player.id,
            name: player.name,
        });
    };

    const calculateRank = (playerId) => {
        const rankList = [];
        let currentRank = 1;

        game.playerList.forEach((player, index) => {
            if (
                index === 0 ||
                player.points < game.playerList[index - 1].points
            ) {
                currentRank = index + 1;
            }
            rankList.push({ id: player.id, rank: currentRank });
        });

        const playerRank = rankList.find(
            (entry) => entry.id === playerId
        )?.rank;
        return playerRank || 'Unranked';
    };

    return (
        <div>
            <div className="flex gap-[4px] flex-col text-gray-300">
                <div className="flex border-b border-b-gray-500 -ml-4 -mr-4 px-4 mb-2 pb-1">
                    <div className="grow">Game Settings</div>
                    <div>
                        <FontAwesomeIcon
                            icon={faGear}
                            className="cursor-pointer hover:text-primary"
                            onClick={handleOpenModal}
                        />
                    </div>
                </div>
                <div className="text-xl">
                    <div className="grow flex gap-2">
                        <div>
                            <span className="pr-1">Game Code:</span>{' '}
                            {game.game_code}
                        </div>
                        <div className="text-base text-primary">
                            <CopyToClipboard
                                textToCopy={`${window.location.origin}/join-game/${game.game_code}`}
                                tooltipMessage="Game link copied!"
                                hoverMessage="Copy game link"
                            >
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    className="opacity-90 pt-[5px]"
                                />
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="text-xl flex gap-10">
                    <div className="grow flex gap-2">
                        <div>Rank: {calculateRank(game.player_id)}</div>
                    </div>
                    <div
                        className="flex align-center items-center gap-[6px] cursor-pointer"
                        onClick={handleOpenModal}
                    >
                        <div className="flex flex-col justify-center">
                            <FontAwesomeIcon
                                icon={faUsers}
                                className="text-sm"
                            />
                        </div>
                        <div>
                            {game.playerList.length}/{game.max_players}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title="Game Settings"
                size="md"
            >
                <div className="text-lg">
                    <div className="text-xl flex gap-2">
                        <div>Game Code: {game.game_code}</div>
                        <div className="text-base text-primary">
                            <CopyToClipboard
                                textToCopy={`${window.location.origin}/join-game/${game.game_code}`}
                                tooltipMessage="Game link copied!"
                                hoverMessage="Copy game link"
                            >
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    className="opacity-90 pt-[5px]"
                                />
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="bg-gray-100 p-2 rounded mt-2">
                        <div className="flex border-b-2 -ml-2 -mr-2 px-2 mb-2">
                            <div className="flex grow">
                                <div className="pr-3">Player List</div>
                                <div>
                                    {game.playerList.length}/{game.max_players}
                                </div>
                            </div>
                            <div>Points</div>
                        </div>
                        <div className="flex flex-col">
                            {game.playerList.map((player, index) => {
                                return (
                                    <div key={index} className="flex gap-2">
                                        {game.is_creator &&
                                        game.player_id !== player.id ? (
                                            <div className="flex flex-col justify-center">
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className="text-sm text-danger opacity-60 cursor-pointer"
                                                    onClick={() =>
                                                        handleRemovePlayer(
                                                            player
                                                        )
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div
                                            className={
                                                game.player_id === player.id
                                                    ? 'flex w-full gap-2 font-bold'
                                                    : 'flex w-full gap-2'
                                            }
                                        >
                                            <div className="grow">
                                                {calculateRank(player.id) +
                                                    ' - '}
                                                {player.name}{' '}
                                                {game.player_id === player.id
                                                    ? '(you)'
                                                    : ''}
                                            </div>
                                            <div>{player.points || 0}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="text-right mt-4">
                        <Button
                            variant="danger"
                            onClick={handleLeaveGame}
                            disabled={isLoadingGame || isUpdatingGame}
                        >
                            {game.is_creator ? 'End Game' : 'Leave Game'}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default GameManager;
