import React from 'react';
import { Outlet } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';

const PublicRoute = () => {
    const { loading } = useAuth();

    if (loading) {
        return (
            <div className="h-[94vh] w-full flex flex-col justify-center items-center">
                <div>
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon text-4xl"
                    />
                </div>
            </div>
        );
    }

    return <Outlet />;
};

export default PublicRoute;
