import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
    label,
    id,
    type = 'text',
    placeholder = '',
    value,
    onChange,
    size = 'md', // sm, md, lg
    variant = 'default', // default, outline, filled
    className = '',
    labelClassName = '',
    disabled = false,
    required = false,
    ...props
}) => {
    const baseStyles = `block w-full rounded-md transition ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed`;
    const baseLabelStyles = `block text-sm font-medium text-gray-200 mb-[1px] ml-[6px] text-left`;

    const sizeStyles = {
        sm: 'py-1 px-2 text-sm',
        md: 'py-2 px-3 text-base',
        lg: 'py-3 px-4 text-lg',
    };

    const variantStyles = {
        default:
            'bg-gray-100 border border-gray-300 focus:ring-blue-500 focus:border-blue-500',
        outline:
            'bg-white border border-gray-400 focus:ring-blue-500 focus:border-blue-500',
        filled: 'bg-gray-200 border border-transparent focus:ring-blue-500 focus:border-blue-500',
    };

    const combinedStyles = `${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]} ${className}`;
    const combinedLabelStyles = `${baseLabelStyles} ${labelClassName}`;

    return (
        <div>
            {label && (
                <label htmlFor={id} className={combinedLabelStyles}>
                    {label}{' '}
                    {/* {required && <span className="text-red-400">*</span>} */}
                </label>
            )}
            <input
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={required}
                className={combinedStyles}
                {...props}
            />
        </div>
    );
};

Input.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string.isRequired, // Ensures the input has a unique ID for accessibility
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    variant: PropTypes.oneOf(['default', 'outline', 'filled']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

export default Input;
