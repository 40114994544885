import './HowToPlay.scss';

const HowToPlay = () => {
    return (
        <div className="flex flex-col gap-[20px] text-center max-w-[1000px] m-auto py-[20px] px-[10px] mt-[10px] mb-[100px]">
            <h1 className="text-[2.8rem] md:text-[3.5rem]">How to Play</h1>
            <div className="text-left flex flex-col gap-10">
                <div>
                    <div className="text-2xl">Create a game</div>
                    <div>Only logged in users can create a game</div>
                </div>
                <div>
                    <div className="text-2xl">Join a game</div>
                    <div>
                        To join a game simply enter the game code (i.e. 123SC)
                        and a your name
                    </div>
                </div>
                <div>
                    <div className="text-2xl">Start a round</div>
                    <div>
                        Any player in the game can start a round by pressing the
                        "Listen for Song" button. The player's device microphone
                        *must* be enabled for SoundCued to listen for a song.
                        Once the song is recognized, the round is officially in
                        progress.
                    </div>
                </div>
                <div>
                    <div className="text-2xl">Playing a game</div>
                    <div>
                        Once a round is in progress, players will be presented
                        with 4 options: either 4 artists or 4 songs. Players
                        will either guess the song or guess the artist. Correct
                        answers earn 5 points, incorrect answers earn 0 points.
                        You only have one attempt per round to answer.
                    </div>
                </div>
                <div>
                    <div className="text-2xl">Ending/Leaving a game</div>
                    <div>
                        The creator of the game can end the game for all players
                        by pressing the "End Game" button in the game settings
                        modal. Non-creator players can leave the game by
                        pressing the "Leave Game" button in the game settings
                        modal.
                    </div>
                </div>
                <div>
                    <div className="text-2xl">Removing players</div>
                    <div>
                        The creator of the game can remove players by pressing
                        the trash can icon in the game settings modal.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToPlay;
