import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Toast } from '../components';
import { useNavigate } from 'react-router';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
    const [game, setGame] = useState(null);
    const navigate = useNavigate();
    // when loading game is true, it shows loader on protected route (refreshing/remounting components)
    // use isUpdating when making changes to an already loaded game
    const [isLoadingGame, setIsLoadingGame] = useState(false);
    const [isUpdatingGame, setIsUpdatingGame] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleTooManyRequests = () => {
        Toast.error('Too many requests. Please try again in a few minutes.');
    };

    // create game, only logged in users can call this
    const createGame = async (gameData) => {
        if (!gameData.player_name) return;

        const storedUser = localStorage.getItem('user');
        let gameCreated = false;

        if (storedUser) {
            setIsLoadingGame(true);
            const user = JSON.parse(storedUser);

            try {
                const response = await axios.post(
                    `${apiBaseUrl}/api/game`,
                    {
                        player_name: gameData.player_name,
                    },
                    {
                        headers: {
                            Authorization: user.token
                                ? `Bearer ${user.token}`
                                : '',
                        },
                    }
                );

                const { game_code, player_id } = response.data.data;
                localStorage.setItem(
                    'game',
                    JSON.stringify({ game_code, player_id })
                );
                setGame(response.data.data);
                Toast.success('Game successfully created');
                gameCreated = true;
            } catch (error) {
                console.error('game creation error:', error);

                if (error.status === 429) {
                    handleTooManyRequests();
                } else if (error.status === 409) {
                    // j-todo: prompt upgrade plan here
                    // maybe disable create button in component
                    Toast.error('Max games created for this period');
                } else {
                    Toast.error('Problem creating game');
                }

                gameCreated = false;
            }
        } else {
            // shouldnt see this, but just in case
            Toast.error(
                'Only signed in users can create games. Please refresh and sign in'
            );
        }

        // Set loading to false once user data is loaded or not found
        setIsLoadingGame(false);
        return gameCreated;
    };

    const leaveGame = async (toastMessage = null) => {
        let leftGame = false;

        // if no game, just ensure cookies are cleaned
        // and nav to home
        if (!game) {
            localStorage.removeItem('game');
            navigate('/');
            return leftGame;
        }

        if (game.is_creator) {
            leftGame = await endGame(toastMessage);
        } else {
            leftGame = await exitGame(toastMessage);
        }

        return leftGame;
    };

    // creator is ending game
    const endGame = async (toastMessage) => {
        const storedUser = localStorage.getItem('user');
        let gameEnded = false;

        if (storedUser) {
            setIsLoadingGame(true);
            const user = JSON.parse(storedUser);

            try {
                const response = await axios.delete(
                    `${apiBaseUrl}/api/game/${game.game_code}`,
                    {
                        headers: {
                            Authorization: user ? `Bearer ${user.token}` : '',
                        },
                    }
                );

                setGame(null);
                localStorage.removeItem('game');

                if (toastMessage) {
                    Toast.success(toastMessage);
                }

                gameEnded = true;
            } catch (error) {
                console.error('end game error:', error);

                if (error.status === 429) {
                    handleTooManyRequests();
                } else if (error.status === 403) {
                    Toast.error('Unable to end game');
                } else {
                    Toast.error('Problem ending game');
                }

                gameEnded = false;
            }
        } else {
            // should never hit here
            Toast.error('Must be logged in to end game');
        }

        setIsLoadingGame(false);
        return gameEnded;
    };

    const exitGame = async (toastMessage) => {
        if (!game.game_id || !game.player_id) return;

        let leftGame = false;
        setIsLoadingGame(true);

        try {
            const response = await axios.post(`${apiBaseUrl}/api/game/leave`, {
                game_id: game.game_id,
                game_code: game.game_code,
                player_id: game.player_id,
            });

            setGame(null);
            localStorage.removeItem('game');
            leftGame = true;
            if (toastMessage) {
                Toast.success(toastMessage);
            }
        } catch (error) {
            console.error('Problem leaving game:', error);

            if (error.status === 429) {
                handleTooManyRequests();
            } else {
                Toast.error('Problem leaving game');
            }

            leftGame = false;
        }

        setIsLoadingGame(false);
        return leftGame;
    };

    const fetchGame = async () => {
        const storedGame = localStorage.getItem('game');

        if (storedGame) {
            setIsLoadingGame(true);
            const gameData = JSON.parse(storedGame);

            try {
                const response = await axios.get(
                    `${apiBaseUrl}/api/game/details`,
                    {
                        params: {
                            game_code: gameData.game_code,
                            player_id: gameData.player_id,
                        },
                    }
                );

                setGame(response.data.data);
            } catch (error) {
                console.error('fetch game error:', error);

                if (error.status === 429) {
                    handleTooManyRequests();
                } else if (error.status === 404) {
                    Toast.error(
                        error?.response?.data?.message || 'Unable to fetch game'
                    );
                    localStorage.removeItem('game');
                } else {
                    Toast.error('Problem getting game');
                }
            }
        }

        setIsLoadingGame(false);
    };

    // join game
    // { game_code: '', player_name: '' }
    const joinGame = async (gameData) => {
        if (!gameData.game_code || !gameData.player_name) return;

        let joinedGame = false;
        setIsLoadingGame(true);

        try {
            const response = await axios.post(
                `${apiBaseUrl}/api/game/join`,
                gameData
            );

            const { game_code, player_id } = response.data.data;
            localStorage.setItem(
                'game',
                JSON.stringify({ game_code, player_id })
            );
            setGame(response.data.data);
            joinedGame = true;
        } catch (error) {
            console.error('Problem joining game:', error);

            if (error.status === 429) {
                handleTooManyRequests();
            } else if (error.status === 404) {
                Toast.error('Game ended or does not exist');
            } else if (error.status === 409) {
                Toast.error('Game cannot be joined, max players reached');
            } else {
                Toast.error('Problem joining game');
            }

            joinedGame = false;
        }

        setIsLoadingGame(false);
        return joinedGame;
    };

    // params = {id, name}
    const removePlayer = async (params) => {
        const { id, name } = params;

        const storedUser = localStorage.getItem('user');
        let playerRemoved = false;

        if (storedUser) {
            setIsUpdatingGame(true);
            const user = JSON.parse(storedUser);

            try {
                const response = await axios.post(
                    `${apiBaseUrl}/api/game/remove-player`,
                    {
                        game_code: game.game_code,
                        player_id: id,
                    },
                    {
                        headers: {
                            Authorization: user ? `Bearer ${user.token}` : '',
                        },
                    }
                );

                Toast.success(`Successfully removed ${name} from game`);

                playerRemoved = true;
            } catch (error) {
                console.error('remove player error:', error);

                if (error.status === 429) {
                    handleTooManyRequests();
                } else if (error.status === 403) {
                    Toast.error('Unable to remove player');
                } else {
                    Toast.error('Problem removing player');
                }

                playerRemoved = false;
            }
        } else {
            // should never hit here
            Toast.error('Must be logged in to remove player');
        }

        setIsUpdatingGame(false);
        return playerRemoved;
    };

    // no api calls, just remove game from local storage and move player to home page
    const shallowRemovePlayer = () => {
        setGame(null);
        localStorage.removeItem('game');
        Toast.success('You have been removed from the game by admin');
        navigate('/');
    };

    const updateGame = (updates) => {
        const updatedGame = {
            ...game,
            ...updates,
        };
        setGame(updatedGame);
    };

    const submitAnswer = async (answer) => {
        let submittedAnswer = false;

        try {
            const response = await axios.post(
                `${apiBaseUrl}/api/game/submit-answer`,
                {
                    player_id: game.player_id,
                    round_id: game.round.id,
                    answer,
                    is_answer_correct:
                        answer.toLowerCase() ===
                        game.round.correct_answer.toLowerCase(),
                    game_code: game.game_code,
                }
            );

            // player answer broadcasts and we respond to it in PlayGame.js

            submittedAnswer = true;
        } catch (error) {
            console.error('submit answer error', error);

            if (error.status === 429) {
                handleTooManyRequests();
            } else if (error.status === 409) {
                Toast.error(
                    'Player already submitted an answer for this round'
                );
            } else {
                Toast.error('Problem submitting answer');
            }

            submittedAnswer = false;
        }

        return submittedAnswer;
    };

    const contextValue = {
        game,
        isLoadingGame,
        isUpdatingGame,
        createGame,
        leaveGame,
        fetchGame,
        joinGame,
        removePlayer,
        shallowRemovePlayer,
        updateGame,
        submitAnswer,
    };

    useEffect(() => {
        fetchGame();
    }, []);

    return (
        <GameContext.Provider value={contextValue}>
            {children}
        </GameContext.Provider>
    );
};
