import { useEffect, useState } from 'react';
import { Input, Button, Modal } from '../../components';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router';
import { Toast } from '../../components';
import axios from 'axios';

import './CreateGame.scss';

const CreateGame = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const { login, user } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [showPage, setShowPage] = useState(false);
    const [signInData, setSignInData] = useState({ email: '', password: '' });
    const [signUpData, setSignUpData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSignInInputChange = (e) => {
        const { name, value } = e.target;
        setSignInData({
            ...signInData,
            [name]: value,
        });
    };

    const handleSignUpInputChange = (e) => {
        const { name, value } = e.target;
        setSignUpData({
            ...signUpData,
            [name]: value,
        });
    };

    const signIn = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const response = await login(signInData);

            if (response.success) {
                navigate('/play-game');
            } else {
                setError(`Login failed. ${response.error}`);
                Toast.error('Problem logging in');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('An error occurred during login.');
            Toast.error('Problem logging in');
        } finally {
            setIsLoading(false);
        }
    };

    const signUp = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        console.log('to sign up', { signUpData });

        try {
            const response = await axios.post(
                `${apiBaseUrl}/api/signup`,
                signUpData
            );

            Toast.success(
                'Signup successful! Please check email for verification code.'
            );
            navigate('/verify');
        } catch (error) {
            console.error('sign up error:', error);

            if (error.status === 429) {
                Toast.error('Too many requests');
            } else if (error.status === 400) {
                Toast.error('Email already in use');
            } else {
                Toast.error('Problem signing up');
            }

            // gameCreated = false;
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            navigate('/play-game');
        } else {
            setShowPage(true);
        }
    }, [user]);

    return (
        <div>
            {showPage && (
                <>
                    <div className="flex flex-col gap-[20px] text-center max-w-[500px] m-auto py-[20px] px-[10px] mt-[20px] md:mt-[40px]">
                        <h1 className="text-[2.8rem] md:text-[3.5rem] -mb-[30px]">
                            Let's do this!
                        </h1>
                        <div className="text-xl">
                            Sign in below to create a game.
                        </div>
                        <form onSubmit={signIn}>
                            <div className="flex flex-col gap-[20px]">
                                <div>
                                    <Input
                                        label="Email"
                                        id="signin-email"
                                        placeholder="Enter your email"
                                        value={signInData.email}
                                        onChange={handleSignInInputChange}
                                        size="md"
                                        variant="default"
                                        type="email"
                                        required
                                        autoComplete="email"
                                        name="email"
                                        className="bg-gray-800"
                                    />
                                </div>
                                <div>
                                    <Input
                                        label="Password"
                                        id="signin-password"
                                        placeholder="Enter your password"
                                        value={signInData.password}
                                        onChange={handleSignInInputChange}
                                        size="md"
                                        variant="default"
                                        type="password"
                                        required
                                        autoComplete="current-password"
                                        name="password"
                                        className="bg-gray-800"
                                    />
                                </div>
                                {error && (
                                    <div className="text-danger">{error}</div>
                                )}
                                <div className="mt-[20px]">
                                    <Button type="submit" disabled={isLoading}>
                                        Sign In
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <div className="text-gray-300">
                            No account yet?{' '}
                            <span
                                className="text-primary cursor-pointer hover:text-primary-hover"
                                onClick={handleOpenModal}
                            >
                                Sign up now (it's free)
                            </span>
                        </div>
                    </div>
                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        title="Sign up for SoundCued"
                        size="md"
                    >
                        <div>
                            <div className="text-center text-xl mb-[20px]">
                                Sign up for a Free SoundCued account
                            </div>
                            <form onSubmit={signUp}>
                                <div className="flex flex-col gap-[20px]">
                                    <div>
                                        <Input
                                            label="First Name"
                                            placeholder="Enter your first name"
                                            id="signup-firstName"
                                            value={signUpData.firstName}
                                            onChange={handleSignUpInputChange}
                                            size="md"
                                            variant="default"
                                            required
                                            name="firstName"
                                            labelClassName="text-gray-500"
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Last Name"
                                            placeholder="Enter your last name"
                                            id="signup-lastName"
                                            value={signUpData.lastName}
                                            onChange={handleSignUpInputChange}
                                            size="md"
                                            variant="default"
                                            required
                                            name="lastName"
                                            labelClassName="text-gray-500"
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Email"
                                            placeholder="Enter your email"
                                            id="signup-email"
                                            value={signUpData.email}
                                            onChange={handleSignUpInputChange}
                                            size="md"
                                            variant="default"
                                            type="email"
                                            required
                                            autoComplete="email"
                                            name="email"
                                            labelClassName="text-gray-500"
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Password"
                                            placeholder="Enter your password"
                                            id="signup-password"
                                            value={signUpData.password}
                                            onChange={handleSignUpInputChange}
                                            size="md"
                                            variant="default"
                                            type="password"
                                            required
                                            autoComplete="current-password"
                                            name="password"
                                            labelClassName="text-gray-500"
                                        />
                                    </div>
                                    <div className="flex justify-end gap-[20px] mt-[20px]">
                                        <Button
                                            variant="secondary"
                                            disabled={isLoading}
                                            onClick={handleCloseModal}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            Sign Up
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Modal>
                </>
            )}
        </div>
    );
};

export default CreateGame;
