import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './context/AuthContext';
import { GameProvider } from './context/GameContext';
import { SocketProvider } from './context/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <GameProvider>
            <AuthProvider>
                <SocketProvider>
                    <App />
                    <ToastContainer />
                </SocketProvider>
            </AuthProvider>
        </GameProvider>
    </Router>
);
