import React, { createContext, useContext, useEffect, useState } from 'react';
import { connectSocket, disconnectSocket } from '../services/socketService';
import { useGame } from './GameContext';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const { game } = useGame();
    const gameCode = game?.game_code;
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (gameCode) {
            const newSocket = connectSocket(gameCode);
            setSocket(newSocket);

            return () => {
                disconnectSocket();
            };
        }
    }, [gameCode]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
