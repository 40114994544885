import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_API_BASE_URL;

let socket = null;

export const connectSocket = (game_code) => {
    if (!socket) {
        // Initialize socket connection
        // game_code must be sent and is used to join the room on backend
        socket = io(SOCKET_URL, {
            query: { game_code },
        });

        socket.on('connect', () => {
            console.log('Connected to game', game_code);
        });

        socket.on('disconnect', () => {
            console.log('Disconnected from game', game_code);
        });
    }

    return socket;
};

export const getSocket = () => {
    if (!socket) {
        throw new Error('Socket connection has not been established yet!');
    }
    return socket;
};

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect();
        socket = null;
    }
};
