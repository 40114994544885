import './NotFound.scss';

const NotFound = () => {
    return (
        <div className="flex flex-col gap-[20px] text-center max-w-[500px] m-auto py-[20px] px-[10px] mt-[100px] md:mt-[100px]">
            <h1 className="text-4xl md:text-5xl text-gray-300">
                Page not found
            </h1>
        </div>
    );
};

export default NotFound;
