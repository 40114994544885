import { Link } from 'react-router';
import { Button } from '../../components';

import './Home.scss';

const Home = () => {
    return (
        <div>
            <div className="flex flex-col text-center m-auto py-[20px] px-[10px] mt-[20px] md:mt-[150px]">
                <h1 className="text-[2.8rem] md:text-[4rem]">
                    Welcome to SoundCued
                </h1>
                <div className="flex flex-col md:flex-row justify-center gap-[20px] mt-[40px]">
                    <div>
                        <Link to="/join-game">
                            <Button>Join Game</Button>
                        </Link>
                    </div>
                    <div>
                        <Link to="/create-game">
                            <Button>Create Game</Button>
                        </Link>
                    </div>
                </div>
                <div className="text-gray-300 mt-[40px] md:mt-[14px] flex justify-center flex-col md:flex-row md:gap-[4px]">
                    <div>First time visiting?</div>
                    <div>
                        Click{' '}
                        <Link
                            to="/how-to-play"
                            className="text-primary hover:text-primary-hover"
                        >
                            here
                        </Link>{' '}
                        to learn how it works.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
