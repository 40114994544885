import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { Toast } from '../components';
import { useGame } from './GameContext';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const { leaveGame } = useGame();
    const navigate = useNavigate();
    const [user, setUser] = useState(null); // Initialize user state with null
    const [loading, setLoading] = useState(true); // Add a loading state

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const updateUser = (updatedUserData) => {
        setUser({
            ...user,
            ...updatedUserData,
        });
    };

    // called on route change from protected routes
    // authenticate in the background, no loading state needed
    const authenticate = async () => {
        const storedUser = localStorage.getItem('user');

        if (!storedUser) {
            signOut();
        } else {
            await axios
                .get(`${apiBaseUrl}/api/authenticate`, {
                    headers: {
                        Authorization: user ? `Bearer ${user.token}` : '',
                    },
                })
                .then((response) => {
                    if (response.data.tokenRefreshed) {
                        // new token
                        const token = response.data.token;
                        // update cookie with refreshed token
                        localStorage.setItem('user', JSON.stringify({ token }));
                        // update user with refreshed token
                        updateUser({ token });
                    }
                })
                .catch((error) => {
                    console.error('invalid authentication', error);
                    signOut();
                });
        }
    };

    // Check if the user is authenticated (e.g., by checking a token in localStorage)
    // fetch full user to share
    const fetchUser = async () => {
        const storedUser = localStorage.getItem('user');

        if (storedUser) {
            const user = JSON.parse(storedUser);

            try {
                const response = await axios.get(`${apiBaseUrl}/api/user`, {
                    headers: {
                        Authorization: user.token ? `Bearer ${user.token}` : '',
                    },
                });

                setUser({
                    ...response.data.user,
                    token: user.token,
                });
            } catch (error) {
                console.error(error);
                signOut();
            }
        }

        // Set loading to false once user data is loaded or not found
        setLoading(false);
    };

    const login = async (formData) => {
        try {
            const response = await axios.post(
                `${apiBaseUrl}/api/signin`,
                formData
            );

            if (response.data.success) {
                const { token, user } = response.data;

                setUser({ token, ...user });
                localStorage.setItem('user', JSON.stringify({ token }));
            }

            return response.data;
        } catch (error) {
            console.error(error.response.data);
            return { success: false, error: error.response.data.error };
        }
    };

    // Perform signOut actions (clear user data and token)
    // we try to delete game by calling leaveGame
    // even if that fails, dont stop the user from being cleared out of cookies
    // we force the game and user to be cleared from local storage
    const signOut = async () => {
        leaveGame();
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('game');
        Toast.success('Successfully logged out');
        navigate('/');
    };

    const contextValue = {
        user,
        loading,
        login,
        signOut,
        authenticate,
        updateUser,
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};
