import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Input } from '../../components';
import GameManager from './GameManager';
import StatusMessage from './StatusMessage';
import RoundContent from './RoundContent';
import { useGame } from '../../context/GameContext';
import { useSocket } from '../../context/SocketContext';

import './PlayGame.scss';

const GAME_CODE_LENGTH = 5;

const PlayGame = () => {
    const {
        isLoadingGame,
        game,
        createGame,
        joinGame,
        submitAnswer,
        shallowRemovePlayer,
        updateGame,
        leaveGame,
    } = useGame();
    const socket = useSocket();

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    // time allowed to record, api shoots for results in 5-15, 10 middle of the road
    const recordingTimeMs = 10000;
    const recordingTimeSec = recordingTimeMs / 1000;
    // how long should round be locked for
    const roundLockSeconds = 30 * 1000;
    const mediaRecorderRef = useRef(null);
    const audioStreamRef = useRef(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [showModal, setshowModal] = useState(false);
    const [gameData, setGameData] = useState({
        game_code: '',
        player_name: '',
    });
    const [modalState, setModalState] = useState(null);
    const [error, setError] = useState(null);
    const [recordingTimer, setRecordingTimer] = useState(recordingTimeSec);
    const [roundLockTimer, setRoundLockTimer] = useState(roundLockSeconds);

    const [roundState, setRoundState] = useState({
        isLoadingRound: false,
        isRoundLocked: false,
        isRecording: false,
        roundStartedBy: '',
    });

    const openJoinGameModal = () => {
        setModalState('join');
        setshowModal(true);
    };

    const openCreateGameModal = () => {
        setModalState('create');
        setshowModal(true);
    };

    const closeModal = () => {
        setshowModal(false);
        setModalState(null);
        setGameData({ game_code: '', player_name: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Apply uppercase and max-length only for "game_code"
        const transformedValue =
            name === 'game_code'
                ? value.toUpperCase().slice(0, GAME_CODE_LENGTH) // Uppercase and limit to 5 characters
                : value;

        setGameData({
            ...gameData,
            [name]: transformedValue,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        let joinedGame = false;

        if (modalState === 'join') {
            joinedGame = await joinGame(gameData);
        } else {
            joinedGame = await createGame(gameData);
        }

        // if the call to create or join succeeds than close
        // otherwise show error for action
        if (joinedGame) {
            closeModal();
        } else {
            if (modalState === 'join') {
                setError('Unable to join game. Please check game code.');
            } else {
                setError('Problem creating game. Please try again.');
            }
        }
    };

    // Start recording when the button is clicked
    const handleStartRecording = async () => {
        // reset song data
        // setSongData(null);
        setRecordingTimer(recordingTimeSec);

        try {
            // Request microphone access
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true,
            });
            audioStreamRef.current = stream;

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;

            // When recording data is available, store it in audioBlob
            mediaRecorder.ondataavailable = (event) => {
                setAudioBlob(event.data);
            };

            // Start the recording
            mediaRecorder.start();
            // recording
            setRoundState((prevState) => ({
                ...prevState,
                isLoadingRound: false,
                isRecording: true,
                isRoundLocked: false,
            }));

            // Stop the recording after a fixed duration
            setTimeout(() => {
                stopRecording();
            }, recordingTimeMs);
        } catch (err) {
            console.error('Error accessing microphone: ', err);
            alert(
                'Please enable microphone permissions in your device settings.'
            );
        }
    };

    // Stop the recording and send the audio data to the backend
    const stopRecording = () => {
        if (
            mediaRecorderRef.current &&
            mediaRecorderRef.current.state !== 'inactive'
        ) {
            mediaRecorderRef.current.stop(); // Stop recording
        }

        if (audioStreamRef.current) {
            const tracks = audioStreamRef.current.getTracks();
            tracks.forEach((track) => track.stop()); // Stop the media stream
        }
    };

    // Send the audio blob to the backend API
    const sendAudioToApi = async () => {
        // loading game, reset started by (socket will tell us who)
        setRoundState((prevState) => ({
            ...prevState,
            isLoadingRound: true,
            roundStartedBy: '',
            isRecording: false,
            isRoundLocked: false,
        }));

        try {
            const formData = new FormData();
            formData.append('audio', audioBlob, 'audio.wav');
            // we might not be in a game (user could be playing by themselves)
            if (game?.game_id) {
                formData.append('game_code', game.game_code);
                formData.append('player_id', game.player_id);
            }
            // append more here as needed

            const response = await axios.post(
                `${apiBaseUrl}/api/song/recognize`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            console.log('Song data received:', response.data);

            // if we're not in game, just set the "round" here
            // otherwise, we will respond to the socket emit and handle there
            // with a real round
            if (!game?.game_id) {
                const localGame = game || {};
                localGame.round = response.data.data;
                updateGame(localGame);
                // local game, ready to play
                setRoundState((prevState) => ({
                    ...prevState,
                    isLoadingRound: false,
                    isRecording: false,
                    isRoundLocked: false,
                }));
            }
        } catch (error) {
            console.error('Error sending audio to API:', error);
            alert('Failed to send audio to API');
            // errored out, show game
            setRoundState((prevState) => ({
                ...prevState,
                isLoadingRound: false,
                isRecording: false,
                isRoundLocked: false,
            }));
        }
    };

    const handleSubmitAnswer = async (answer) => {
        // no real game, just update our "round" here
        // no need to call api as we dont store these
        // just sets the game locally
        if (!game?.game_id) {
            const localGame = game;
            localGame.round.playerAnswer = {
                answer,
                is_answer_correct:
                    answer.toLowerCase() ===
                    localGame.round.correct_answer.toLowerCase(),
            };
            updateGame(localGame);
            return;
        }

        const submittedAnswer = await submitAnswer(answer);
    };

    useEffect(() => {
        let interval;

        if (roundState.isRecording) {
            if (game?.game_id) {
                broadcast('round_starting');
            }

            interval = setInterval(() => {
                setRecordingTimer((prev) => prev - 1); // Decrease timer by 1 every second
            }, 1000);
        }

        return () => clearInterval(interval); // Cleanup interval when the component unmounts or recording stops
    }, [roundState.isRecording]);

    useEffect(() => {
        // If audioBlob is set, send it to the backend
        if (audioBlob) {
            sendAudioToApi();
        }
    }, [audioBlob]); // Dependency array ensures this runs when audioBlob changes

    // listen for socket updates
    useEffect(() => {
        if (socket) {
            const handleRoundStarting = (data) => {
                // round is loading, update player name
                setRoundState((prevState) => ({
                    ...prevState,
                    isLoadingRound: true,
                    roundStartedBy: data.player_name,
                    isRecording: false,
                    isRoundLocked: false,
                }));
            };

            const handleRoundStarted = (data) => {
                const updatedGame = game;
                updatedGame.round = data;
                updateGame(updatedGame);
                // round started, lock round
                setRoundState((prevState) => ({
                    ...prevState,
                    isLoadingRound: false,
                    isRecording: false,
                    isRoundLocked: true,
                }));
            };

            // add player to the list
            const handlePlayerJoined = (data) => {
                const updatedGame = game;
                updatedGame.playerList.push({
                    name: data.player_name,
                    is_creator: data.player_is_creator,
                    id: data.player_id,
                    points: data.player_points,
                });
                updateGame(updatedGame);
            };

            const handleAnswerSubmitted = (data) => {
                const updatedGame = game;

                // update the player points in the list
                updatedGame.playerList = updatedGame.playerList
                    .map((player) =>
                        player.id === data.player_id
                            ? { ...player, points: data.player_points }
                            : player
                    )
                    .sort((a, b) => b.points - a.points);

                // if the event was for this player
                // update thier answer in the game
                if (updatedGame.player_id === data.player_id) {
                    updatedGame.round.playerAnswer = {
                        answer: data.answer,
                        is_answer_correct: data.is_answer_correct,
                    };
                }

                updateGame(updatedGame);
            };

            // filter list by player id
            const handlePlayerLeft = (data) => {
                const updatedGame = game;
                updatedGame.playerList = updatedGame.playerList.filter(
                    (player) => player.id !== data.player_id
                );
                updateGame(updatedGame);
            };

            // game ended for everyone
            const handleGameEnded = (data) => {
                leaveGame('Game was ended by admin');
            };

            const handlePlayerRemoved = (data) => {
                // if the player removed was this player, remove them from game
                // the api call did the actual removal, so just do a shallow boot here
                if (data.player_id === game.player_id) {
                    shallowRemovePlayer();
                }

                // j-todo: do we need else here?

                // otherwise remove the player from the list
                const updatedGame = game;
                updatedGame.playerList = game.playerList.filter(
                    (player) => player.id !== data.player_id
                );
                updateGame(updatedGame);
            };

            socket.on('round_starting', handleRoundStarting);
            socket.on('round_started', handleRoundStarted);
            socket.on('player_joined', handlePlayerJoined);
            socket.on('answer_submitted', handleAnswerSubmitted);
            socket.on('player_left', handlePlayerLeft);
            socket.on('player_removed', handlePlayerRemoved);
            socket.on('game_ended', handleGameEnded);

            return () => {
                socket.off('round_starting', handleRoundStarting);
                socket.off('round_started', handleRoundStarted);
                socket.off('player_joined', handlePlayerJoined);
                socket.on('answer_submitted', handleAnswerSubmitted);
                socket.off('player_left', handlePlayerLeft);
                socket.off('game_ended', handleGameEnded);
                socket.off('player_removed', handleGameEnded);
            };
        }
    }, [socket]);

    useEffect(() => {
        // Clean up the stream when the component unmounts
        return () => {
            if (audioStreamRef.current) {
                const tracks = audioStreamRef.current.getTracks();
                tracks.forEach((track) => track.stop()); // Stop the stream when the component is removed
            }
        };
    }, []);

    const broadcast = (event) => {
        if (socket) {
            socket.emit(event, { player_name: game.player_name });
        }
    };

    useEffect(() => {
        if (!game) return;

        // local games dont have a created at, so single player local games wont wait
        const roundCreatedAt = game?.round?.created_at;
        if (roundCreatedAt) {
            const createdAt = new Date(roundCreatedAt).getTime();
            const now = Date.now();
            const timeDifference = roundLockSeconds - (now - createdAt);

            if (timeDifference > 0) {
                setRoundLockTimer(Math.ceil(timeDifference / 1000));
                // locking game
                setRoundState((prevState) => ({
                    ...prevState,
                    isLoadingRound: false,
                    isRecording: false,
                    isRoundLocked: true,
                }));

                const interval = setInterval(() => {
                    const newNow = Date.now();
                    const newTimeDifference =
                        roundLockSeconds - (newNow - createdAt);

                    if (newTimeDifference <= 0) {
                        setRoundLockTimer(0);
                        // let the game be fully played
                        setRoundState((prevState) => ({
                            ...prevState,
                            isLoadingRound: false,
                            isRecording: false,
                            isRoundLocked: false,
                        }));
                        clearInterval(interval);
                    } else {
                        setRoundLockTimer(Math.ceil(newTimeDifference / 1000));
                    }
                }, 1000);

                return () => clearInterval(interval);
            }
        }
    }, [game]);

    return (
        <div className="px-[10px] mt-[10px] md:mt-[40px] pb-[40px]">
            {/* game manager  */}
            {game?.game_id ? (
                <div className="w-full mb-[20px] flex md:justify-end">
                    <div className="bg-gray-800 p-4 pt-2 rounded-lg shadow-lg w-full md:w-fit">
                        <GameManager />
                    </div>
                </div>
            ) : (
                <>
                    <div className="w-full bg-gray-800 px-4 py-8 md:py-4 rounded-lg shadow-lg mb-[20px] text-lg flex flex-col gap-[20px] md:flex-row">
                        <div className="grow flex flex-col justify-center">
                            <div>
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    className="mr-1"
                                />{' '}
                                SoundCued is better with friends! Create or join
                                a game now to get started.
                            </div>
                        </div>
                        <div className="text-center">
                            <Button
                                onClick={openCreateGameModal}
                                disabled={isLoadingGame}
                                className="w-full md:w-fit"
                            >
                                <FontAwesomeIcon icon={faPlus} /> Create Game
                            </Button>
                        </div>
                        <div className="text-center">
                            <Button
                                onClick={openJoinGameModal}
                                disabled={isLoadingGame}
                                className="w-full md:w-fit"
                            >
                                Join Game
                            </Button>
                        </div>
                    </div>
                </>
            )}
            <div className="flex gap-[20px] flex-col md:flex-row w-full">
                {/* audio controller  */}
                <div
                    className={`w-full md:w-1/2 bg-gray-800 px-4 py-2 rounded-lg shadow-lg min-h-[200px] md:min-h-[350px] flex flex-col justify-center text-center ${
                        roundState.isRecording ? 'pulse' : ''
                    }`}
                >
                    <StatusMessage
                        roundState={roundState}
                        recordingTimer={recordingTimer}
                        roundLockTimer={roundLockTimer}
                    />
                    <div>
                        <Button
                            onClick={handleStartRecording}
                            disabled={
                                roundState.isRecording ||
                                roundState.isLoadingRound ||
                                roundState.isRoundLocked
                            }
                            className="w-full md:w-fit"
                        >
                            Listen for Song
                        </Button>
                    </div>
                </div>

                {/* game component  */}
                <div className="w-full md:w-1/2 bg-gray-800 px-4 py-2 rounded-lg shadow-lg min-h-[320px] flex flex-col justify-center text-center">
                    <RoundContent
                        game={game}
                        roundState={roundState}
                        handleSubmitAnswer={handleSubmitAnswer}
                    />
                </div>
            </div>
            {/* join/create modal  */}
            <Modal
                isOpen={showModal}
                onClose={closeModal}
                title={modalState === 'join' ? 'Join Game' : 'Create Game'}
                size="md"
            >
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-[20px]">
                        {modalState === 'join' && (
                            <div>
                                <Input
                                    label="Game Code"
                                    id="game_code"
                                    placeholder="Enter game code"
                                    value={gameData.game_code}
                                    onChange={handleInputChange}
                                    size="md"
                                    variant="default"
                                    required
                                    name="game_code"
                                    labelClassName="text-gray-500"
                                />
                            </div>
                        )}
                        <div>
                            <Input
                                label="Name"
                                id="player_name"
                                placeholder="Enter your name"
                                value={gameData.player_name}
                                onChange={handleInputChange}
                                size="md"
                                variant="default"
                                required
                                name="player_name"
                                labelClassName="text-gray-500"
                            />
                        </div>
                        {error && (
                            <div className="text-danger text-base">{error}</div>
                        )}
                        <div className="mt-[20px] text-right">
                            <Button type="submit" disabled={isLoadingGame}>
                                {modalState === 'join' ? 'Join' : 'Create'} Game
                            </Button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default PlayGame;
