import { useState } from 'react';
import { NavLink } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowRightFromBracket,
    faMusic,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../context/AuthContext';
import { useGame } from '../../context/GameContext';

import './Header.scss';

const Header = () => {
    const { user, signOut } = useAuth();
    const { game } = useGame();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen((prev) => !prev);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    const handleSignout = () => {
        closeMobileMenu();
        signOut();
    };

    return (
        <header className="header-component-container px-4 py-6">
            <div className="flex items-center justify-between">
                {/* Logo Section */}
                <h1>
                    {user || game ? (
                        <div className="flex items-center gap-1">
                            <FontAwesomeIcon
                                icon={faMusic}
                                className="text-sm mr-1"
                            />
                            <span className="font-semibold text-base">
                                SoundCued
                            </span>
                        </div>
                    ) : (
                        <NavLink
                            to="/"
                            className="flex items-center gap-1 hover:text-primary"
                        >
                            <FontAwesomeIcon
                                icon={faMusic}
                                className="text-sm mr-1"
                            />
                            <span className="font-semibold text-base">
                                SoundCued
                            </span>
                        </NavLink>
                    )}
                </h1>

                {/* Desktop Navigation */}
                <nav className="hidden md:flex items-center gap-8">
                    <NavLink
                        to="/how-to-play"
                        className={({ isActive }) =>
                            isActive ? 'border-b' : 'hover:text-primary'
                        }
                    >
                        How to Play
                    </NavLink>
                    {user || game ? (
                        <>
                            <NavLink
                                to="/play-game"
                                className={({ isActive }) =>
                                    isActive ? 'border-b' : 'hover:text-primary'
                                }
                            >
                                Play Game
                            </NavLink>
                            {/* only users can logout  */}
                            {user && (
                                <div
                                    className="hover:text-primary cursor-pointer"
                                    onClick={handleSignout}
                                >
                                    <FontAwesomeIcon
                                        icon={faArrowRightFromBracket}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <NavLink
                                to="/join-game"
                                className={({ isActive }) =>
                                    isActive ? 'border-b' : 'hover:text-primary'
                                }
                            >
                                Join Game
                            </NavLink>
                            <NavLink
                                to="/create-game"
                                className={({ isActive }) =>
                                    isActive ? 'border-b' : 'hover:text-primary'
                                }
                            >
                                Create Game
                            </NavLink>
                        </>
                    )}
                </nav>

                {/* Mobile Hamburger Menu */}
                <button
                    className="md:hidden text-gray-100 hover:text-primary focus:outline-none"
                    onClick={toggleMobileMenu}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 5.75h16.5m-16.5 6h16.5m-16.5 6h16.5"
                        />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu Modal */}
            <div
                className={`fixed inset-0 z-50 bg-black bg-opacity-50 flex flex-col justify-start items-start md:hidden transition-transform duration-300 ease-in-out ${
                    isMobileMenuOpen
                        ? 'opacity-100 translate-x-0'
                        : 'opacity-0 translate-x-full pointer-events-none'
                }`}
                onClick={closeMobileMenu}
            >
                <div
                    className="bg-backgroundDark w-full py-6 px-4 h-full"
                    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                >
                    <div className="flex justify-between items-center border-b -mx-4 px-4 pb-4">
                        <h1>
                            {user || game ? (
                                <div className="flex items-center gap-1">
                                    <FontAwesomeIcon
                                        icon={faMusic}
                                        className="text-sm mr-1"
                                    />
                                    <span className="font-semibold text-base">
                                        SoundCued
                                    </span>
                                </div>
                            ) : (
                                <NavLink
                                    to="/"
                                    onClick={closeMobileMenu}
                                    className="flex items-center gap-1 hover:text-primary"
                                >
                                    <FontAwesomeIcon
                                        icon={faMusic}
                                        className="text-sm mr-1"
                                    />
                                    <span className="font-semibold text-base">
                                        SoundCued
                                    </span>
                                </NavLink>
                            )}
                        </h1>
                        <button
                            onClick={closeMobileMenu}
                            className="hover:text-primary"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <nav className="mt-4 flex flex-col gap-4">
                        <div>
                            <NavLink
                                to="/how-to-play"
                                onClick={closeMobileMenu}
                                className={({ isActive }) =>
                                    isActive ? 'border-b' : 'hover:text-primary'
                                }
                            >
                                How to Play
                            </NavLink>
                        </div>
                        {user || game ? (
                            <>
                                <div>
                                    <NavLink
                                        to="/play-game"
                                        onClick={closeMobileMenu}
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'border-b'
                                                : 'hover:text-primary'
                                        }
                                    >
                                        Play Game
                                    </NavLink>
                                </div>
                                {/* only users can logout  */}
                                {user && (
                                    <div>
                                        <span
                                            className="cursor-pointer hover:text-primary"
                                            onClick={handleSignout}
                                        >
                                            Sign out
                                        </span>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div>
                                    <NavLink
                                        to="/join-game"
                                        onClick={closeMobileMenu}
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'border-b'
                                                : 'hover:text-primary'
                                        }
                                    >
                                        Join Game
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink
                                        to="/create-game"
                                        onClick={closeMobileMenu}
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'border-b'
                                                : 'hover:text-primary'
                                        }
                                    >
                                        Create Game
                                    </NavLink>
                                </div>
                            </>
                        )}
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;
