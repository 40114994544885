import React from 'react';
import { Routes, Route } from 'react-router';
import {
    CreateGame,
    Home,
    HowToPlay,
    JoinGame,
    NotFound,
    PlayGame,
    VerifyToken,
} from './pages';
import { Header } from './components';
import { ProtectedRoute, PublicRoute } from './routes';

import './App.scss';

const App = () => {
    return (
        <div className="min-h-screen max-w-full sm:max-w-screen-2xl mx-auto text-white">
            <header>
                <Header />
            </header>
            <main>
                <Routes>
                    {/* public routes  */}
                    <Route element={<PublicRoute />}>
                        {/* home  */}
                        <Route exact path="/" element={<Home />} />
                        {/* create game  */}
                        <Route
                            exact
                            path="/create-game"
                            element={<CreateGame />}
                        />
                        {/* how to play  */}
                        <Route
                            exact
                            path="/how-to-play"
                            element={<HowToPlay />}
                        />
                        {/* join game, with or without gamecode  */}
                        <Route exact path="/join-game" element={<JoinGame />} />
                        <Route
                            path="/join-game/:gameCode"
                            element={<JoinGame />}
                        />
                        {/* verify token  */}
                        <Route exact path="/verify" element={<VerifyToken />} />
                        {/* 404  */}
                        <Route path="*" element={<NotFound />} />
                    </Route>

                    {/* private routes  */}
                    <Route element={<ProtectedRoute />}>
                        {/* play-game  */}
                        <Route exact path="/play-game" element={<PlayGame />} />
                    </Route>
                </Routes>
            </main>
        </div>
    );
};

export default App;
