import React, { useEffect } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/AuthContext';
import { useGame } from '../context/GameContext';

const ProtectedRoute = () => {
    const { user, loading, authenticate } = useAuth();
    const { game, isLoadingGame } = useGame();
    const location = useLocation();

    // if user is logged in, authenticate on protected route changes
    useEffect(() => {
        if (user) {
            authenticate();
        }
    }, [location, user]);

    // loading user or loading game, show loader
    if (loading || isLoadingGame) {
        return (
            <div className="h-[94vh] w-full flex flex-col justify-center items-center">
                <div>
                    <FontAwesomeIcon
                        icon={faSpinner}
                        className="fa-spin icon text-4xl"
                    />
                </div>
            </div>
        );
    }

    return user || game ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
